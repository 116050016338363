.dashboardNav{
    display: flex;
    flex-direction:row;
    height: 100vh;

    .left{
        display: flex;
        flex-direction: column;
        flex: 0.15;
        // position:sticky;
    }
    
    .right{
        display: flex;
        flex-direction: column;
        flex: 0.85;
        background-color: rgba(10, 156, 188, 0.1);
        overflow: scroll;
    }  
}


