.left{
    background-color:rgba(71, 35, 217, 0.7);
    color: #fff;
    padding:10px;
    height: '100%';

    h3{
        font-weight:500;
        text-align: center;
    }
    ul{
        margin: 0;
        li{
            margin-right: 10px;
            list-style: none;
            margin: 20px 0px;
            display: flex;
            align-items: center;
            &:hover{
                background-color: rgba(71, 35, 217, 0.9);
                
            }      
            a{
                text-decoration: none;
            }

            .icon{
                font-size: 20px;
                margin-right: 10px;
                color:#fff;
            }
            span{
                font-size: 20px;
                font-weight: 400;
                color: #fff;
                cursor: pointer;
                
                &:active{
                    background-color: rgba(71, 35, 217, 0.9);  
                }

                
            }
        }
        
    }
         
}