.table{
    background-color: rgba(10, 156, 188, 0.1);

    .stats{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 30px;
        
        h4{
            border: 1px solid #50C2D1;
            padding:20px;
            border-radius: 10px;
            background-color: #0E4046;
            color: rgb(236, 239, 223);
            font-size: large;
            font-weight: 400;
            &:hover{
                cursor: pointer;
            }
        }
    }

    .tableCell{
        font-size: large;
        background-color: rgba(10, 156, 188, 0.1);
        // text-align: center;
    }

    }

    .listen{
        background-color:#ffff80;
        color: black;
        border-radius: 10px;
        padding:5px;
        font-size:15px;
        &:hover{
            cursor: pointer;
            background-color: #e6e600;
           }
    }

    .delete{
        background-color: #f47171;
        color: black;
        border-radius: 10px;
        padding:5px;
        font-size:15px;
        &:hover{
            cursor: pointer;
            background-color: #ec1313;
           }
    }


