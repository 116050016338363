.login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(222, 242, 246);
    height: 100vh;
    width: 100vw;

    h1{
        color: rgb(90, 0, 18);
        font-weight: 400;
    }
    h3{
        color: rgb(90, 0, 18);
        font-weight: 400;
    }
    
    .card{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        height: 30%;
        width: 20%;
        background-color: rgb(222, 242, 246);
        
        input{
        padding: 10px;
        border: none;
        border-bottom:2px gray solid;  
        border-color: rgb(77, 76, 74);
        background-color: rgb(222, 242, 246);
        margin-top:30px;
        display: block;
        }

        button{
            margin-top: 10px;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;
            &:hover{
                background-color: rgba(92, 210, 231, 0.987);
            }
            display: block;
        }

        
    }
}