.navbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h5{
    padding:10px;
    font-size: 20px;
    font-weight: 400;
}
.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid rgb(83, 80, 80);
    padding: 3px;
    float: right;
    border-radius: 5px;
    width:300px;
    
input {
  border: none;
  outline: none;
  background: transparent;

      &::placeholder {
        font-size: 12px;
      }
    }
  }

.button-style{

    float: right;
    display:flex;
    align-items:flex-end;
    justify-content:center;
    width:250px;
    padding:10px;
    margin:20px;
    border-radius: 10px;
    
    &:hover{
        cursor: pointer;
        background-color: antiquewhite;
    }
  }     
