.top{
    display: flex;
    flex-direction: row;
    background-color: #F0F5F5;
    .leftTop{
        flex:0.2;
        }
    .middle{
        flex: 0.6;
        background-color: rgb(255, 255, 255);
        margin-bottom:20px;
        @media (max-width:1000px) {
            position:fixed;
            width:100%;
            height: 100%;
            overflow: scroll;
        }
        h2{
            color: #0A74BC;
            font-weight: 600;
            text-align: center;
            padding:15px;
        }
        .middle_div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: rgba(10, 156, 188, 0.09);
           .please{
            color: #000;
            font-weight: 500;
            text-align: center;
            padding: 10px;
           }
           p{
            text-align: center;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            color: #000;
           }
           .card{
            width: 40%;
            height: 300px;
            margin-top: 10px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px  rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            align-content: space-around;
            position: relative;


            @media(max-width:1150px){
                width:65%;
            }
            .textLabel{
                margin:30px;
                color: red;
            }
            .button{
                margin:40px;
                padding:20px;
                background:#0A9CBC;
                box-shadow:0px 4px 8px rgba(0, 0, 0, 0.2);
                border-radius:50%;
                border: solid 1px #087891;
                color: #FFFFFF;
                font-size:large;
                position: absolute;
            }
            .mic{
                height:25px;
                width:25px;
            }
            .waveDiv{
                width:100%;
                padding: 5px;
            }
        }
            }
            .aboutUs{
                padding:20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .video{
                    padding-bottom:20px;
                    width:70vw;
                }
                .aboutText{
                    width:90%;
                    line-height:30px;
                }
        }
    }
    .rightTop{
        flex: 0.2;
    }
}




















