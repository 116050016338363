
.form{
    display: flex;
    flex-direction: column;
    
    h1{
        font-weight: 400;
    }
    .icon{
        font-size: 200px;
        opacity: 0.9;
        padding: 10px;
        
    }

    .button{
        display: flex;
        flex-direction: row;
        margin: 5px;
        padding: 10px;
        margin-top: 10px;

       button{
        margin: 10px;

       } 
    } 
}
